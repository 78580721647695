import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SharedModule } from './shared/shared.module';


const routes: Routes = [
  {
    path: "",
    loadChildren: () => import('./features/home-page/home-page.module').then(m => m.HomePageModule)
  },
  // { path: 'blog', loadChildren: () => import('./features/blog/blog.module').then(m => m.BlogModule) },
  // { path: 'blog', loadChildren: () => import('./features/blog/blog.module').then(m => m.BlogModule) },
  // { path: 'blog/:slug', loadChildren: () => import('./features/blog/blog.module').then(m => m.BlogModule) },
  {
    path: "**",
    redirectTo: "",
    pathMatch: "full"
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
}), SharedModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
