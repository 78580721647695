import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { MaterialModule } from "./material.module";
import { TransPipe } from "./pipes/trans.pipe";
import { MarkdownModule } from "ngx-markdown";
import { ImageUrlPipe } from "./pipes/imageUrl.pipe";

@NgModule({
  declarations: [HeaderComponent, FooterComponent, TransPipe, ImageUrlPipe],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    TranslateModule.forChild(),
    MarkdownModule.forChild()
  ],
  exports: [HeaderComponent, FooterComponent, TransPipe, ImageUrlPipe]
})
export class SharedModule {}
