import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { AuthService } from "../../core/auth/services/auth.service";
import { User } from '../../core/auth/models/auth.model';

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {}
}
